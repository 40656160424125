import React, { useEffect, useState, useRef } from 'react';
import { api } from '../../services/api';
import { Howl } from 'howler';

import bipSoundUrl from '../../Assets/sound/bip.mp3';
import RestScreen from '../../components/RestScreen/RestScreen';
import Loading from '../../components/Loading/Loading';
import Product from '../../components/Product/Product';
import NotFound from '../../components/Product/NotFound/NotFound';

const Fipan = () => {
  const [gtin, setGtin] = useState('');
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [product, setProduct] = useState({
    name: '',
    price: '',
    priceOffer: '',
    fideliPrice: '',
    image: '',
    active: 0,
  });

  const inputRef = useRef(null);

  const bipSound = new Howl({
    src: [bipSoundUrl],
  });

  const getGtin = async () => {
    try {
      bipSound.play();
      setLoading(true);

      const { data: vtex } = await api.post('product_vtex', { ean: gtin });
      const { data: bluesoft } = await api.post('product_bluesoft/feira', { ean: gtin });

      if (vtex.Error) {
        if (bluesoft.name === '' || !bluesoft.priceOffer) {
          setNotFound(true);
          setLoading(false);
          setGtin('');
          inputRef.current.focus();
        } else {
          setLoading(false);
          setProduct({
            name: bluesoft.description,
            price: bluesoft.priceNormal,
            priceOffer: bluesoft.priceOffer,
            fideliPrice: bluesoft.priceFidelidade,
            image: '',
            active: 1,
          });
          inputRef.current.focus();
        }
        resetTime();
        setGtin('');
        inputRef.current.focus();
      } else {
        setLoading(false);
        setProduct({
          name: bluesoft.description,
          price: bluesoft.priceNormal,
          priceOffer: bluesoft.priceOffer,
          fideliPrice: bluesoft.priceFidelidade,
          image: vtex.urlImage[0].ImageUrl,
          active: 1,
        });
        resetTime();
        setGtin('');
        inputRef.current.focus();
      }
    } catch (error) {
      setNotFound(true);
      setLoading(false);
      setGtin('');
      resetTime();
      inputRef.current.focus();
    }
  };

  const resetTime = () => {
    setTimeout(() => {
      setProduct({
        name: '',
        price: '',
        priceOffer: '',
        fideliPrice: '',
        image: '',
        active: 0,
      });
      setNotFound(false);
      focusInput();
    }, 12000);
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    focusInput();
  }, []);


  return (
    <div className="container_app">
      <input
        ref={inputRef}
        autoComplete="off"
        id="inputEan"
        type="text"
        inputMode="numeric"
        value={gtin}
        name="gtin"
        onChange={(e) => setGtin(e.target.value)}
        onKeyDown={(e) => e.keyCode === 13 && getGtin()}
      />

      {loading && (
        <Loading />
      )}

      {!notFound && product.active === 0 && (
        <div className="iframe-container">
          <RestScreen />
        </div>
      )}

      {!notFound && product.active === 1 && <Product product={product} />}

      {notFound && <NotFound />}
    </div>
  );
};

export default Fipan;
