import React from 'react';
import './styles.css'

import Icon from '../../Assets/img/Icon.svg';

export default function Loading() {
  return (
    <div className="loading container_app">
      <img className="loading-image" src={Icon} alt="" />
      <p className='loading-text'>CONSULTANDO PREÇO...</p>
    </div>
  );
}