import React from 'react';
import './styles.css';

import ClubeMeire from "../../Assets/img/clube.png";
import ClubeConfeitaria from "../../Assets/img/clube-confeitaria.png";
import Arrow from "../../Assets/img/Arrow.svg";
import formatPrice from '../../utils/formatPrice';

export default function Product({ product }) {
  const discount = Math.floor((product.price - product.priceOffer) / product.price * 100);
  const discountClube = Math.floor((product.price - product.fideliPrice) / product.price * 100);

  return (
      <div className="product-found fullwidth">
        <div className='product-found-container'>
          {product.image ? (
            <div className='product-image-container'>
              <img className="product-image" src={product.image} alt={product.name} />
            </div>
          ) : null}
          <div className="product-infos">
            <h1 className="product-name">{product.name}</h1>
            <div className="prices-and-clube">
              <div className="product-prices">
                {/* desconto */}
                {product.priceOffer < product.price && product.priceOffer && (
                  <div className="discount-box">
                    <span className="discount-value"> {formatPrice(product.price)}</span>
                    <div className='discount-porcent-box'>
                      <img src={Arrow} alt="arrow" />
                      <span className="discount-porcent">{discount}%</span>
                    </div>
                  </div>
                )}
                {/* preço */}
                <span className="price-offer">
                   {product.priceOffer < product.price ? formatPrice(product.priceOffer) : formatPrice(product.price)}
                </span>
              </div>

              {/* clube meire */}
              {product.fideliPrice > 0 && (
                <div className="club-box">
                  <div className="club-discount">
                    <span className="club-label">Clube da Meire</span>
                    <div className='club-discount-box'>
                      <img src={Arrow} alt="arrow" />
                      <span className="club-discount-text">{discountClube}%</span>
                    </div>
                  </div>
                  <span className="club-price">{formatPrice(product.fideliPrice)}</span>
                </div>
              )}
            </div>
          </div>
        </div>

        {product.fideliPrice ? (
          <div>
            <img className="club" src={ClubeMeire} alt="Clube Meire" />
          </div>
        ) : (
          <div>
            <img className="club" src={ClubeConfeitaria} alt="Clube" />
          </div>
        )}

      </div>
  );
}