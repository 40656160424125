import React, { createContext, useEffect, useState } from "react";

import { api } from "../services/api";

import history from '../history'

const Context = createContext()

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({
		user: '',
		password: '',
	})
	const [messageError, setMessageError] = useState('')
	const [isAuthenticated, setisAuthenticated] = useState(false)
	const [loading, setLoading] = useState(true)

	const submit = async () => {
		try {
			const res = await api.post('appkeyPriceReader', user)
			if (res.data) {
				localStorage.setItem('@APP:token', res.data.token)
				setisAuthenticated(true)
				history.push('/')
			}
		} catch (error) {
			setMessageError('Usuário ou senha inválidos')
			let time = setInterval(() => {
				setMessageError('')
				clearInterval(time)
			}, 5000)
		}
	}

	const handleLogin = (e) => {
		e.preventDefault()
		submit()
	}

	const verifyToken = async () => {

		const { data } = await api.post('verifyToken')
		console.log(data.message)
		if (data.message === 'Token invalid') {
			history.push('/login')
		}
	}


	useEffect(() => {
		const token = localStorage.getItem('@APP:token')
		if (token) {
			setisAuthenticated(true)
		}

		setLoading(false)
	}, [])

	useEffect(() => {
		verifyToken()
	}, [])


	return (
		<Context.Provider value={{ user, setUser, handleLogin, isAuthenticated, loading, messageError }}>
			{children}
		</Context.Provider>
	)
}

export { Context, AuthProvider }