import React from "react";
import "./styles.css";

import ClubeConfeitaria from "../../../Assets/img/clube-confeitaria.png";

export default function NotFound() {
  return (
    <div className="not-found fullwidth">
      <div className="not-found-container">
        <span className="product-message">Produto não encontrado.</span>
      </div>

      <div>
        <img className="club" src={ClubeConfeitaria} alt="Clube" />
      </div>
    </div>
  );
}
